import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  set(event) {
    this.inputTarget.value = this.actualValue(event.target)
  }

  actualValue(elem) {
    if (elem.dataset.value) { return elem.dataset.value }

    return elem.innerText
  }
}
