import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static values = { options: String }

  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) { return  }

    let settings = {}

    if (this.optionsValue) {
      settings = {
        persist: true,
        createOnBlur: true,
        create: true,
        maxItems: 1,
        delimiter: "§$",
        options: JSON.parse(this.optionsValue).map(entry => { return { value: entry, text: entry } })
      }
    }

    new TomSelect(this.element, settings)
  }
}
