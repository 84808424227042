import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropdown"]

  connect() {
    this.uid = Math.floor(Math.random() * 42);
    this.data.set("open", false)
    this.data.set("uid", this.uid)

    document.body.addEventListener("click", this.__hideDropdownHandler.bind(this))
  }

  disconnect() {
    document.body.removeEventListener("click", this.__hideDropdownHandler.bind(this))
  }

  openDropdown(e) {
    e.preventDefault()
    this.dropdownTarget.classList.add("is--active")
    this.data.set("open", true)
  }

  hideDropdown(e) {
    if(!this.isOpen()) { return true }
    if(!this.getDropdownContainer(e)) {
      this.dropdownTarget.classList.remove("is--active")
      this.data.set("open", false)
    }
  }

  getDropdownContainer(e) {
    return e.target.closest(`.c-dropdown[data-dropdown-uid="${this.uid}"]`)
  }

  isOpen() {
    return !!this.data.get("open")
  }

  __hideDropdownHandler(e) { this.hideDropdown(e) }
}
