import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { content: String }
  static targets = ["content"]

  copyToClipboard(event) {
    event.stopPropagation()
    event.preventDefault()

    const value = this.contentValue || this.contentTarget.innerText
    navigator.clipboard.writeText(value)
  }
}
