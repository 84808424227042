import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.boundCloseFunction = this.__closeFunction.bind(this)
    this.mainElement.addEventListener("click", this.boundCloseFunction)
  }

  disconnect() {
    this.mainElement.removeEventListener("click", this.boundCloseFunction)
  }

  toggleNav() { this.navElement.classList.toggle("is--active") }
  closeNav() { this.navElement.classList.remove("is--active") }

  get mainElement() { return document.querySelector("main") }
  get navElement() { return document.querySelector("nav") }

  __closeFunction(e) {
    if(e.target == this.element) { return true }
    this.closeNav()
  }
}
