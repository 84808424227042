import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    document.addEventListener("keyup", this.__changeFunction.bind(this))
    document.addEventListener("change", this.__changeFunction.bind(this))
    document.querySelectorAll("input").forEach( (element) => this.setFilledFlag(element) )
  }

  disconnect() {
    document.removeEventListener("keyup", this.__changeFunction.bind(this))
    document.removeEventListener("change", this.__changeFunction.bind(this))
  }

  setFilledFlag(element) {
    if(element.value != null && element.value != "") {
      element.classList.add("is--filled")
    } else {
      element.classList.remove("is--filled")
    }
  }

  __changeFunction(e) {
    const target = e.target
    if(target.tagName == "INPUT") {
      this.setFilledFlag(target)
    }
  }
}
