import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["gross", "net", "tax", "taxPercentage"]

  calculate() {
    const net = to_float(this.netTarget),
          tax = round(parseFloat(net * this.taxPercentage)),
          gross = round(net + tax)

    this.tax = tax
    this.gross = gross
  }

  calculateWithCustomTax() {
    const net = to_float(this.netTarget),
          tax = to_float(this.taxTarget),
          gross = round(net + tax)

    this.tax   = tax
    this.gross = gross
  }

  get taxPercentage() { return round(to_float(this.taxPercentageTarget) / 100) }
  set tax(value) { this.taxTarget.value = to_formatted_string(value) }
  set gross(value) { this.grossTarget.value = to_formatted_string(value) }
}

function to_float(field) {
  if(field.value == "") { return 0 }

  return round(parseFloat(field.value.replace(".", "").replace(",", ".")))
}

function round(num) { return Math.round(num * 100) / 100 }

function to_formatted_string(value) {
  value = value.toString().replace(".", ",")
  if(!value.includes(",")) { value = `${value},00` }

  return value
}
