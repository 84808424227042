import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  delete(e) {
    e.preventDefault()

    if(window.confirm(this.data.get("message"))) {
      this.formTarget.requestSubmit()
    }
  }
}
