import { Turbo } from "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"

// Stimulus initialization
const application = Application.start()
const controllers = import.meta.globEager("../controllers/**/*_controller.js")
registerControllers(application, controllers)

// Direct upload functionality
ActiveStorage.start()
