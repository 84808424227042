import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["filename", "file", "status"]

  // We're always getting a FileList, even when we don't allow multiples.
  fileSelected(e) {
    const fileList = e.target.files
    const file = fileList[0]

    this.statusTarget.value = file.name
    if (!this.filenameTarget.value) { this.filenameTarget.value = file.name }
  }
}
