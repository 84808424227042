import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.request = null
    this.request_delta = 200
    this.__keyDownFunction = this.__keyDownFunction.bind(this)

    document.addEventListener("keydown", this.__keyDownFunction)
  }

  disconnect() {
    document.removeEventListener("keydown", this.__keyDownFunction)
  }

  search() {
    const path = this.data.get("path")
    const searchQuery = encodeURIComponent(this.searchString)
    const queryURL = `${path}?query=${searchQuery}`

    fetch(queryURL, { credentials: "same-origin" })
      .then(response => {
        return response.text().then(text => {
          this.resultContainer.innerHTML = text
        })
      })
      .catch(error => console.error(error))
  }

  highlightNextRow() {
    let rows = this.resultRows,
        foundRow = findRowForSelection(rows)

    if(foundRow) {
      foundRow.classList.add("is--active")
    } else {
      this.resultRows[0].classList.add("is--active")
    }
  }

  highlightPreviousRow() {
    let rows = Array.from(this.resultRows).reverse(),
        foundRow = findRowForSelection(rows)

    if(foundRow) {
      foundRow.classList.add("is--active")
    } else {
      this.resultRows[this.resultRows.length - 1].classList.add("is--active")
    }
  }

  selectRow() {
    this.data.delete("open")
    this.searchField.blur()
    if(this.activeRow) { this.activeRow.querySelector("a").click() }
  }

  close() {
    this.data.delete("open")
    this.element.classList.remove("is--visible")
    this.contentContainer.classList.remove("is--hidden")
    this.searchField.value = ""
    this.resultContainer.innerHTML = ""
  }

  handleInput(e) {
    this.data.set("open", true)
    this.contentContainer.classList.add("is--hidden")
    this.element.classList.add("is--visible")
    this.searchField.focus()

    window.clearTimeout(this.request)
    this.request = setTimeout(() => { this.search() }, this.request_delta)
  }

  __keyDownFunction(e) {
    const target = e.target
    const is_form = ["INPUT", "SELECT", "TEXTAREA"].includes(target.tagName)
    const is_modified = e.metaKey || e.ctrlKey || e.altKey
    const is_relevant = e.which >= 48 && e.which <= 57 ||
                        e.which >= 65 && e.which <= 90 ||
                        this.isOpen && e.which == 8 // Backspace is only relevant when Jarvis is open

    // If Jarvis is closed and we"re in a form element or the key was modified, there"s nothing for us to do.
    if(!this.isOpen && (is_form || is_modified)) { return true }

    if(this.isOpen) {
      switch(e.which) {
      case 27: // Escape closes Jarvis
        this.close()
        break
      case 38: // Arrow up
        e.preventDefault()
        this.highlightPreviousRow()
        break
      case 40: // Arrow down
        e.preventDefault()
        this.highlightNextRow()
        break
      case 13: // Enter
        e.preventDefault()
        e.stopPropagation()
        this.selectRow()
        break
      default:
        if(is_relevant) { this.handleInput() }
      }
    } else {
      if(is_relevant) { this.handleInput() }
    }
  }

  get isOpen() { return this.data.has("open") }
  get contentContainer() { return document.querySelector("#content") }
  get searchField() { return this.element.querySelector(".js--jarvis-query") }
  get searchString() { return this.searchField.value }
  get resultContainer() { return this.element.querySelector(".js--jarvis-results") }
  get resultRows() { return this.resultContainer.querySelectorAll(".js--jarvis-result") }
  get activeRow() { return this.resultContainer.querySelector(".js--jarvis-result.is--active") }
}

function findRowForSelection(rows) {
  let foundRow = false

  rows.forEach((row, i) => {
    if(!row.classList.contains("is--active")) { return }

    // Deselect the current row
    row.classList.remove("is--active")
    // If we are at least the second row, keep the previous one for selection.
    if(i < rows.length - 1) { foundRow = rows[i + 1] }
  })

  return foundRow
}
