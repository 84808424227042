import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.currentTag = null
  }

  toggleHighlight(event) {
    const selectedTag = event.params.tag

    // Clicking the same tag a second time deactivates the highlight/fade out again.
    if (selectedTag === this.currentTag) {
      document.querySelectorAll(`[data-highlight-tag]`).forEach(elem => elem.classList.remove("not--highlighted"))
      return
    }

    document.querySelectorAll("[data-highlight-tag]").forEach((elem) => {
      elem.classList.toggle("not--highlighted", elem.dataset.highlightTag !== selectedTag)
    })

    this.currentTag = selectedTag
  }
}
