import { Controller } from "@hotwired/stimulus"
import TinyDatePicker from "tiny-date-picker"

const formatDate = (date) => {
  return date.toLocaleDateString(
    "de-DE",
    {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    }
  )
}

const parseDateString = (str) => {
  if(str instanceof Date) { return str }
  if(str == "") { return new Date }

  const parts = str.split(".")
  const date  = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`)

  if(isNaN(date)) { return new Date }

  return date
}

export default class extends Controller {
  connect() {
    TinyDatePicker(this.element, {
      mode: "dp-below",
      hilightedDate: new Date(),
      dayOffset: 1,
      format(date) { return formatDate(date)},
      parse(str) { return parseDateString(str) },
    })
  }
}
